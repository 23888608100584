<div class="add-event">
  <div class="label-add-event">
    <span>Добавить мероприятие</span>
    <img (click)="close()" src="assets/images/ul-kit-img/close-dropdown.svg">
  </div>
  <div class="custom-multiselect-search-event">
    <mat-label class="text-inter-regular">Выберите мероприятия</mat-label>
    <custom-selection
      [multipleCheckBox]="true"
      [control]="eventControl"
      [options]="item"></custom-selection>

    <div class="event">
      <div *ngFor="let selected of getSelectedItems(); let i = index"  class="selected-event">
        <span class="name-event" >{{ selected?.name }}</span>
        <img (click)="deleteSelectedEvent(selected.id)" *ngIf="selected?.name" src="assets/images/ul-kit-img/delete-filter.svg">
      </div>
    </div>
  </div>
  <div  class="bth-search">
    <button (click)="cancel()" class="button-red"><span class="cancel">Отменить</span></button>
    <button (click)="save()" class="button-green"><span class="accept">Сохранить</span></button>
  </div>
</div>
