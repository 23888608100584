import { Component } from '@angular/core';
import {HeaderComponent} from "../../layout/header/header.component";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {ProfileTableComponent} from "./profile-table/profile-table/profile-table.component";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {CustomSelectionComponent} from "../../../assets/shared/components/select/custom-selection/custom-selection.component";
import {MatFormField, MatFormFieldModule, MatLabel} from "@angular/material/form-field";
import {MatInput, MatInputModule} from "@angular/material/input";
import {PaginatorComponent} from "../../../assets/shared/components/paginator/paginator.component";
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle
} from "@angular/material/datepicker";
import {DatepickerComponent} from "../../../assets/shared/components/datepicker/datepicker/datepicker.component";
import {
  DropdownFilterComponent
} from "../../../assets/shared/components/dropdown/dropdown-filter/dropdown-filter/dropdown-filter.component";
import {DropdownComponent} from "../../../assets/shared/components/dropdown/dropdown-basic/dropdown.component";
import {DropdownService} from "../../core/services/dropdown/dropdown.service";

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    HeaderComponent,
    NgIf,
    NgClass,
    NgForOf,
    ProfileTableComponent,
    DropdownModule,
    FormsModule,
    CustomSelectionComponent,
    MatLabel,
    MatInput,
    PaginatorComponent,
    MatFormField,
    MatDatepickerToggle,
    MatDatepickerInput,
    MatDatepicker,
    MatFormFieldModule, MatInputModule, MatDatepickerModule, DatepickerComponent, DropdownFilterComponent, DropdownComponent,
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent {
  item = [
    {id: '1', name:'ID объекта'},
    {id: '2', name:'Наименование'},
    {id: '3', name:'Описание'},
    {id: '4', name:'Наименование на английском языке'},
    {id: '5', name:'Тематический блок'},
    {id: '6', name:'Место проведения'},
    {id: '7', name:'Тест1'},
    {id: '8', name:'Тест2'},
  ]
  supplementalMenu = [
    { label: 'Создать карточку', url: '' },
    { label: 'Блокировать', url: '' },
    { label: 'Удалить', url: '' },
    { label: 'Зарегистрировать на событие', url: '' },
    { label: 'Выбрать слот питания', url: '' }
  ];
  settingsMenu = [
    {
      label: 'Списки ВФМС',
      url: '',
      subMenu: [
        { label: 'Волонтеры', url: '' },
        { label: 'Участники', url: '' },
        { label: 'СМИ', url: '' },
        { label: 'Гости', url: '' },
        { label: 'Тех. персонал', url: '' }
      ],
      icon: 'assets/images/ul-kit-img/arrow-black.svg',
    },
    { label: 'Отчеты',
      subMenu: undefined,
      icon: undefined,
    },
    { label: 'История изменения атрибутов',
      subMenu: undefined,
      icon: undefined,
    },
  ];
  constructorMenu = [
    { label: 'Группировка атрибутов', url: '' },
    { label: 'Расположение атрибутов', url: '' },
    { label: 'История изменения атрибутов', url: '' },
    { label: 'Настройка атрибутов', url: '' },
    { label: 'Массовое изменение атрибутов', url: '' },
    { label: 'Шаблоны', url: '' }
  ];

  constructor(public dropdownService: DropdownService) {
  }

  openDropDown(dropdownName: string): void {
    this.dropdownService.openDropDown(dropdownName);
  }


}
