import {Component, OnInit} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./core/services/auth/auth.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{

  constructor(
    public http: HttpClient,
    // private spinner: NgxSpinnerService,
    private authService: AuthService) {
  }
  ngOnInit() {
    this.authService.registerEvents();
    // this.spinner.show();
  }

}


