import {Component, EventEmitter, Output} from '@angular/core';
import {CustomSelectionComponent} from "../../../select/custom-selection/custom-selection.component";
import {MatLabel} from "@angular/material/form-field";
import {NgForOf, NgIf} from "@angular/common";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-dropdown-filter',
  standalone: true,
    imports: [
        CustomSelectionComponent,
        MatLabel,
        NgForOf,
        NgIf
    ],
  templateUrl: './dropdown-filter.component.html',
  styleUrl: './dropdown-filter.component.scss'
})
export class DropdownFilterComponent {
  item = [
    {id: '1', name:'Международный молодежный форум «Евразия Global»'},
    {id: '2', name:'Всероссийский молодежный образовательный форум «ШУМ»'},
    {id: '3', name:'Международный форум гражданского участия #МЫВМЕСТЕ'},
    {id: '4', name:'Четвертый Евразийский женский форум'},
  ]
  @Output() isDropdownOpenFilterEvent: EventEmitter<void> = new EventEmitter<void>();
  eventControl = new FormControl([]);
  deleteSelectedEvent(id: string) {
    this.eventControl.setValue(this.eventControl.value!.filter((selectedId: string) => selectedId !== id));
  }
  getSelectedItems() {
    return this.item.filter(i => (this.eventControl.value as string[]).includes(i.id));
  }
  cancel() {
    this.eventControl.setValue([])
  }
  save(){

  }

  close(){
    this.isDropdownOpenFilterEvent.emit()
  }
}
