import {Component, EventEmitter, Output} from '@angular/core';
import {
  CustomSelectionComponent
} from "../../../../../assets/shared/components/select/custom-selection/custom-selection.component";
import {MatLabel} from "@angular/material/form-field";
import {NgForOf, NgIf} from "@angular/common";
import {FormControl, FormsModule} from "@angular/forms";

@Component({
  selector: 'app-profile-filter-event',
  standalone: true,
  imports: [
    CustomSelectionComponent,
    MatLabel,
    NgIf,
    NgForOf,
    FormsModule
  ],
  templateUrl: './profile-filter.component-event.html',
  styleUrl: './profile-filter.component-event.scss'
})
export class ProfileFilterComponentEvent {
  item = [
    {id: '1', name:'Международный молодежный форум «Евразия Global»'},
    {id: '2', name:'Всероссийский молодежный образовательный форум «ШУМ»'},
    {id: '3', name:'Международный форум гражданского участия #МЫВМЕСТЕ'},
    {id: '4', name:'Четвертый Евразийский женский форум'},
  ]
  @Output() isDropdownOpenFilterEvent: EventEmitter<void> = new EventEmitter<void>();
  eventControl = new FormControl([]);

  getSelectedItems() {
    return this.item.filter(i => (this.eventControl.value as string[]).includes(i.id));
  }
  closeDropdown() {
    this.isDropdownOpenFilterEvent.emit();
  }
}
