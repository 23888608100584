import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatFormField, MatFormFieldModule} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {NgxPaginationModule} from "ngx-pagination";
import {MatInputModule} from "@angular/material/input";
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  standalone: true,
  imports: [
    MatFormField,
    MatSelect,
    MatOption,
    MatInputModule,
    MatFormFieldModule,
    NgxPaginationModule,
    NgForOf
  ],
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
  isActive: boolean = false;
  @Input() value: number = 100;
  @Output() changePage: EventEmitter<number> = new EventEmitter<number>();
  @Output() changeTake: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild(MatSelect) matSelect!: MatSelect;
  pageChanged(event: number): void {
    this.changePage.emit(event);
  }

  takeChanged(event: number): void {
    this.changeTake.emit(event);
    this.matSelect.close();
  }
  checkIfActive(event: boolean) {
    this.isActive = event;
  }
}
