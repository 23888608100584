<app-table [tableHeaders]="tableHeaders">
  <ng-container>
    <tr class="b-table" *ngFor="let item of items | paginate: {
    itemsPerPage: 23,
    currentPage: 1,
    totalItems: 1000 }; let i = index" [ngClass]="{'checked-background': item.checked}" (click)="routerInCard()">
      <td (click)="$event.stopPropagation()"><a class="b-table__link first-b-table__link"><mat-checkbox [(ngModel)]="item.checked" ></mat-checkbox></a></td>
      <td><a class="b-table__link"><img src="assets/images/table-image/check.svg"></a></td>
      <td><a class="b-table__link">{{ item.status }}</a></td>
      <td><a class="b-table__link">{{ item.description }}</a></td>
      <td><a class="b-table__link">{{ item.participant }}</a></td>
      <td><a class="b-table__link">{{ item.id1 }}</a></td>
      <td><a class="b-table__link">{{ item.id2 }}</a></td>
      <td><a class="b-table__link">{{ item.date }}</a></td>
      <td><a class="b-table__link">{{ item.age }}</a></td>
      <td><a class="b-table__link">{{ item.title }}</a></td>
    </tr>
  </ng-container>
</app-table>
