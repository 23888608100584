import {Component} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {MatInput} from "@angular/material/input";
import {
  CustomSelectionComponent
} from "../../../../../assets/shared/components/select/custom-selection/custom-selection.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatSelect} from "@angular/material/select";
import {MatCheckbox} from "@angular/material/checkbox";
import {ProfileCardService} from "../profile-card.service";
import {UntilDestroy} from "@ngneat/until-destroy";
@UntilDestroy()
@Component({
  selector: 'app-general-information',
  standalone: true,
  imports: [
    NgForOf,
    MatInput,
    NgIf,
    CustomSelectionComponent,
    ReactiveFormsModule,
    MatSelect,
    MatCheckbox
  ],
  templateUrl: './general-information.component.html',
  styleUrl: './general-information.component.scss'
})
export class GeneralInformationComponent {
 isActive: boolean = true;
  formProfile = this.profileCardService.formProfile
  contentItemLabel  = [
    {name : 'Почта', value: 'mail'},
    {name : 'Статус ЛК', value: 'status'},
    {name : 'Категория', value: 'category'},
    {name : 'Причина блокировки', value: 'blocking'},
    {name : 'Тест', value: 'test'},
  ]
  status = [
    { name : 'Активен', id : '1' },
    { name : 'Заблокирован', id : '2' },
    { name : 'На модерацию', id : '3' },
  ]
  category = [
    { name : 'Российский участник (18-35)', id : '1' },
  ]



  constructor(private profileCardService:ProfileCardService) {

  }
}
