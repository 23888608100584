<div [class.gap-unset]="!isActive" [formGroup]="formProfile" class="form">
  <div (click)="isActive = !isActive" class="label-form">
    <img [class.rotate-180]="isActive" src="assets/images/ul-kit-img/arrow-black.svg">
    <span>Основная информация</span>
  </div>
  <div [class.active]="isActive" class="form-content">
    <div class="left-content">
      <div *ngFor="let item of contentItemLabel" class="item-content-label">
        <img src="assets/images/ul-kit-img/green-point.svg">
        <span class="text-inter-regular">{{item.name}}</span>
      </div>
    </div>
    <div class="right-content">
      <div class="item-info">
        <input class="input-main-search"
               formControlName="profileId"
               matInput>
      </div>
      <div class="item-info">
        <input class="input-main-search"
               formControlName="participantId"
               matInput>
      </div>
      <div class="item-info">
        <custom-datepicker [control]="formProfile.controls.registration"></custom-datepicker>
      </div>
      <div class="item-info">
        <custom-selection
          [multipleCheckBox]="false"
          [control]="formProfile.controls.age"
          [options]="age"></custom-selection>
      </div>
      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formProfile.controls.salutation"
                          [options]="salutation"></custom-selection>

      </div>
      <div class="item-info block-with-checkbox">
        <input
          class="input-main-search"
          formControlName="lastName"
          matInput>


        <mat-checkbox formControlName="noLastName"
          class="custom-checkbox">
          <label class="checkbox-label">
            Нет фамилии</label></mat-checkbox>
      </div>


      <div class="item-info block-with-checkbox">
        <input
          class="input-main-search"
          formControlName="firstName"
           matInput>


        <mat-checkbox formControlName="noFirstName"
                      class="custom-checkbox">
          <label class="checkbox-label">Нет имени</label>
        </mat-checkbox>

      </div>
      <div class="item-info block-with-checkbox">
        <input class="input-main-search"
               formControlName="middleName"
               matInput>
        <mat-checkbox formControlName="noMiddleName"
                      class="custom-checkbox">
          <label class="checkbox-label">Нет отчества</label></mat-checkbox>
      </div>
      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formProfile.controls.gender"
                          [options]="gender"></custom-selection>
      </div>
      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formProfile.controls.language"
                          [options]="language"></custom-selection>
      </div>
      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formProfile.controls.language"
                          [options]="language"></custom-selection>
      </div>
      <div class="item-info">
        <input class="input-main-search"
               formControlName="familyName"
               matInput>
      </div>
      <div class="item-info">
        <input class="input-main-search"
               formControlName="familyName"
               matInput>
      </div>
      <div class="item-info">
        <input class="input-main-search"
               formControlName="familyName"
               matInput>
      </div>

      <div class="item-info">
        <custom-datepicker [control]="formProfile.controls.registration"></custom-datepicker>
      </div>

      <div class="item-info">
        <custom-datepicker [control]="formProfile.controls.registration"></custom-datepicker>
      </div>
      <div class="item-info block-with-checkbox">
        <input
          class="input-main-search"
          formControlName="lastName"
          matInput>


        <mat-checkbox formControlName="noLastName"
                      class="custom-checkbox">
          <label class="checkbox-label">
            Российская федерация</label></mat-checkbox>
      </div>
      <div class="item-info">
        <input class="input-main-search"
               formControlName="familyName"
               matInput>
      </div>

      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formProfile.controls.language"
                          [options]="language"></custom-selection>
      </div>
      <div class="item-info">
        <input class="input-main-search"
               formControlName="familyName"
               matInput>
      </div>

      <div class="item-info">
        <input class="input-main-search"
               formControlName="firstNameEn"
                matInput>
      </div>
      <div class="item-info tools">
        <button [class.disabled-button]="!isEdit" class="button-classic"
                [disabled]="!isEdit">
          <img src="assets/images/ul-kit-img/update.svg">
        </button>
        <button [class.disabled-button]="!isEdit" class="button-classic"
                [disabled]="!isEdit">
          <img src="assets/images/ul-kit-img/download.svg">
        </button>
        <button [class.disabled-button]="!isEdit" class="button-classic"
                [disabled]="!isEdit">
          <img src="assets/images/ul-kit-img/look.svg">
        </button>

      </div>
      <div class="item-info">
        <input
          class="input-main-search"
          formControlName="middleNameEn"
           matInput>
      </div>
      <div class="item-info">
        <input
          class="input-main-search"
          formControlName="middleNameEn"
          matInput>
      </div>
      <div class="item-info">
        <input
          class="input-main-search"
          formControlName="middleNameEn"
          matInput>
      </div>

<!--      Доделать логику,в форму пока не входит это-->
      <div class="item-info tools">
        <button [class.disabled-button]="!isEdit" class="button-classic"
                [disabled]="!isEdit">
          <img src="assets/images/ul-kit-img/update.svg">
        </button>
        <button [class.disabled-button]="!isEdit" class="button-classic"
                [disabled]="!isEdit">
          <img src="assets/images/ul-kit-img/download.svg">
        </button>
        <button [class.disabled-button]="!isEdit" class="button-classic"
                [disabled]="!isEdit">
          <img src="assets/images/ul-kit-img/look.svg">
        </button>

      </div>
    </div>
  </div>
</div>
