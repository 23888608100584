import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption, MatSelect, MatSelectModule} from "@angular/material/select";
import {MatTooltip} from "@angular/material/tooltip";
import {NgForOf, NgIf} from "@angular/common";
import {MatCheckbox, MatCheckboxModule} from "@angular/material/checkbox";
import {MatOptionModule} from "@angular/material/core";

@Component({
  selector: 'custom-selection',
  standalone: true,
  templateUrl: './custom-selection.component.html',
  imports: [
    MatLabel,
    MatFormField,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    MatSelect,
    ReactiveFormsModule,
    FormsModule,
    MatOption,
    MatTooltip,
    NgForOf,
    NgIf,
    MatCheckbox
  ],
  styleUrls: ['./custom-selection.component.scss']
})
export class CustomSelectionComponent {
  @Input() options: any[] = [];
  @Input() multipleCheckBox = false;
  @Input() control: FormControl<any | null> = new FormControl(false);
  @ViewChild(MatSelect) matSelect!: MatSelect;
  isActive: boolean = false;




  accept(){
    // здесь будет логика
    this.matSelect.close();
  }
  cancel() {
    this.control.setValue([]);
    this.matSelect.close();
  }
  getSelectedCount(): string {
    if (this.multipleCheckBox) {
      return this.control.value?.length > 0
        ? `Выбрано ${this.control.value.length || 0} из ${this.options.length}`
        : 'Ничего не выбрано';
    } else {
      return this.options.find(option => option.id === this.control.value)?.name || 'Ничего не выбрано';
    }
  }

  selectedAll(isChecked: boolean) {
    if (isChecked) {
      this.control.setValue(this.options.map(o => o.id));
    } else {
      this.control.setValue([]);
    }
  }
  isSelectedCheckAll(): boolean {
    return this.control.value?.length === this.options.length;
  }
  checkIfActive(event: boolean) {
    this.isActive = event;
  }
}
