import {Component, Input} from '@angular/core';
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {IDropDown} from "../../../../../app/core/interface/dropdown/dropdown.interface";

@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [
    NgForOf,
    NgClass,
    NgIf
  ],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss'
})
export class DropdownComponent {
 @Input() menuItems: IDropDown[] | undefined
  isDropdownOpenSubSetting: boolean = false




}
