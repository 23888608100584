<div [class.margin-bottom-unset]="!isActive"
     [formGroup]="formProfile"
     class="form">
  <div (click)="isActive = !isActive" class="label-form">
    <img  [class.rotate-180]="isActive" src="assets/images/ul-kit-img/arrow-black.svg">
    <span >Общая информация</span>
  </div>
  <div [class.active]="isActive" class="form-content" >
    <div  class="left-content">
      <div *ngFor="let item of contentItemLabel" class="item-content-label">
        <img src="assets/images/ul-kit-img/green-point.svg">
        <span>{{item.name}}</span>
      </div>
    </div>
    <div class="right-content">
      <div class="item-info">
        <input
               formControlName="email"
               class="text-inter-semibold input-main-search"
               matInput>
      </div>
      <div class="item-info">

        <custom-selection [multipleCheckBox]="false"
                          [control]="formProfile.controls.status"
                          [options]="status"></custom-selection>
      </div>
      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [options]="category"
                          [control]="formProfile.controls.category"
                          ></custom-selection>
      </div>
      <div class="item-info">
        <input class="input-main-search"

               formControlName="blocking"
               matInput>
      </div>
      <div class="item-info block-with-checkbox">
        <input (keypress)="$event.preventDefault()"
               class="input-main-search input-checkbox"
               formControlName="test"
               matInput>
        <mat-checkbox
          formControlName="test"
          class="custom-checkbox">
          <label class="checkbox-label"></label></mat-checkbox>
      </div>
    </div>
  </div>
</div>
