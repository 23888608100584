import {Component, Input} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
export interface ICustomTableHeaders {
  columnDisplayName: string,
  columnName: string,
}
@Component({
  selector: 'app-table',
  standalone: true,
  imports: [
    NgForOf,
    NgIf
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss'
})


export class TableComponent {
  @Input() tableHeaders: ICustomTableHeaders[] = [];
}
