import { Component } from '@angular/core';
import {NgIf} from "@angular/common";
import {AuthService} from "../../../../../app/core/services/auth/auth.service";

@Component({
  selector: 'app-dropdown-profile',
  standalone: true,
    imports: [
        NgIf
    ],
  templateUrl: './dropdown-profile.component.html',
  styleUrl: './dropdown-profile.component.scss'
})
export class DropdownProfileComponent {
  isDropdownOpen = false;

constructor(private authService:AuthService ) {
}


  async logout(){
    await this.authService.logout()
  }
}
