import { Routes } from '@angular/router';
import {HomeComponent} from "./pages/home/home.component";
import {ProfileComponent} from "./pages/profile/profile.component";
import {ApplicationComponent} from "./pages/application/application.component";
import {AnalyticsComponent} from "./pages/analytics/analytics.component";
import {InstructionsComponent} from "./pages/instructions/instructions.component";
import {MailingComponent} from "./pages/mailing/mailing.component";
import {LoginComponent} from "./autorization/components/auth/login/login.component";
import {AuthorizeGuard} from "./autorization/auth.guard";
import {LoginCompletedComponent} from "./autorization/components/auth/login-completed/login-completed.component";
import {ProfileCardComponent} from "./pages/profile/profile-card/profile-card/profile-card.component";

let ErrorComponent;
export const routes: Routes = [
  {
    path: 'home' ,
    component: HomeComponent,
    canActivate: [AuthorizeGuard]
  },
  {
    path: 'profile' ,
    canActivate: [AuthorizeGuard],
    children:[
      {
        path: '',
        component: ProfileComponent,
      },
      {
        path: 'profile-card',
        component: ProfileCardComponent,
      },
    ]
  },
  {
    path: 'application' ,
    component: ApplicationComponent,
    canActivate: [AuthorizeGuard]
  },
  {
    path: 'analytics' ,
    component: AnalyticsComponent,
    canActivate: [AuthorizeGuard]
  },
  {
    path: 'instructions' ,
    component: InstructionsComponent,
    canActivate: [AuthorizeGuard]
  },
  {
    path: 'mailing' ,
    component: MailingComponent,
    canActivate: [AuthorizeGuard]
  },


  // Auth routing
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthorizeGuard] },
  { path: 'signin-oidc', component: LoginCompletedComponent },
  { path: 'signout-callback-oidc', redirectTo: '/' },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/home'  },
];
