<div class="header">
  <div class="header-block-logo">
    <img [routerLink]="'/home'" src="assets/images/ul-kit-img/logo.svg">
    <div class="navbar-menu">
      <div
        [routerLink]="[item.url]"
        routerLinkActive="active"
        class="text-inter-regular nav-item"
        *ngFor="let item of navMenu">
        <span>{{item.name}}</span>
      </div>
    </div>
    <div class="info-user" (click)="openDropDown('isDropdownOpenProfileHeader')">
      <span class="user-name">Иванов И.И</span>
      <img src="assets/images/ul-kit-img/arrow-down-white.svg"
           [class.rotate-180]="dropdownService.dropdownStates.isDropdownOpenProfileHeader">
    </div>
  </div>
  <div class="dropdown" *ngIf="dropdownService.dropdownStates.isDropdownOpenProfileHeader">
    <app-dropdown-profile></app-dropdown-profile>
  </div>
</div>



