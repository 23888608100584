import { Component } from '@angular/core';
import {HeaderComponent} from "../../../../layout/header/header.component";
import {ButtonBackComponent} from "../../../../../assets/shared/components/button-back/button-back.component";
import {NgClass, NgIf} from "@angular/common";
import {GeneralInformationComponent} from "../general-information/general-information.component";
import {BasicInformationComponent} from "../basic-information/basic-information.component";
import {DropdownComponent} from "../../../../../assets/shared/components/dropdown/dropdown-basic/dropdown.component";
import {ProfileCardService} from "../profile-card.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {DropdownService} from "../../../../core/services/dropdown/dropdown.service";


@UntilDestroy()
@Component({
  selector: 'app-profile-card',
  standalone: true,
  imports: [
    HeaderComponent,
    ButtonBackComponent,
    NgClass,
    GeneralInformationComponent,
    BasicInformationComponent,
    DropdownComponent,
    NgIf
  ],
  templateUrl: './profile-card.component.html',
  styleUrl: './profile-card.component.scss'
})
export class ProfileCardComponent {
  isEdit?: boolean
  supplementalMenu = [
    { label: 'Печатать' , url: ''},
    { label: 'Обновить' , url: ''},
    { label: 'Выгрузить' , url: ''},
    { label: 'Предпросмотр бейджа' , url: ''},
    { label: 'Документы' , url: ''},
    { label: 'Блокировать' , url: ''},
    { label: 'Настроить расположение атрибутов' , url: ''},
    { label: 'Зарегистрировать на событие' , url: ''},
    { label: 'Выбрать слот питания' , url: ''},
  ]

  constructor(public profileCardService:ProfileCardService,public dropdownService: DropdownService ) {
    this.profileCardService.isEdit$.pipe(untilDestroyed(this)).subscribe(isEdit =>{
      this.isEdit = isEdit
    });
  }

  isEditForm(): void {
    this.profileCardService.isEditForm();

  }
  openDropDown(dropdownName: string): void {
    this.dropdownService.openDropDown(dropdownName);
  }
  canselForm(): void {
    this.profileCardService.canselForm()
  }


}
