<div class="body">
  <div class="container">
    <div class="pagination-box">
      <pagination-controls
        (pageChange)="pageChanged($event)"
        previousLabel=""
        nextLabel="">
      </pagination-controls>



      <div class="custom-paginator">
        <span class="text-inter-medium">Строк:</span>
        <mat-form-field  appearance="outline">
          <img [class.rotate-180]="isActive" src="assets/shared/components/paginator/src/app/assets/image/arrow-pagination-select.svg">
          <mat-label></mat-label>
          <mat-select [class.dropdown-open]="isActive"
                       [value]="value"
                        panelClass="custom-panel"
                       (selectionChange)="takeChanged($event.value)"
                       (openedChange)="checkIfActive($event)">
            <mat-option  class="custom-select-pagination" *ngFor="let option of [100,200,500,1000,10000]" [value]="option" >{{ option }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>

