<div class="container-main-table">
  <table class="main-table">
    <thead>
    <tr class="h-table">
      <ng-container *ngFor="let column of tableHeaders">
        <th>
          <div class="actions">
            <div class="column-checkbox">
              <mat-checkbox class="checkbox-lable"   *ngIf="['id'].includes(column.columnName)"></mat-checkbox>
              <span>{{ column.columnDisplayName }}</span>
            </div>
            <div  class="actions-bth">
              <ng-container *ngIf="!['id'].includes(column.columnName)">
                <img  src="assets/images/table-image/arrow_table.svg">
                <img src="assets/images/table-image/sort_table.svg">
              </ng-container>
            </div>
          </div>
        </th>
      </ng-container>
    </tr>
    </thead>
    <tbody>
    <ng-container>
      <ng-content></ng-content>
    </ng-container>
<!--    <ng-template #noData>-->
<!--      <tr>-->
<!--        <td class="no-data">-->
<!--          Нет данных.-->
<!--        </td>-->
<!--      </tr>-->
<!--    </ng-template>-->
    </tbody>
  </table>
</div>
