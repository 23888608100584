export const environment = {
  production: false,
  baseUrl: 'https://dev-crm.wyffest.com',


  auth: {
    issuer: 'https://dev-sso.wyffest.com',
    selfUrl: 'https://dev-crm.wyffest.com',
    clientId: 'vfm.crm',
    scope: 'openid profile roles offline_access'
  }
};
