import {Component} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {MatFormField, MatInput} from "@angular/material/input";
import {DatepickerComponent} from "../../../../../assets/shared/components/datepicker/datepicker/datepicker.component";
import {
  CustomSelectionComponent
} from "../../../../../assets/shared/components/select/custom-selection/custom-selection.component";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatCheckbox} from "@angular/material/checkbox";
import {ReactiveFormsModule} from "@angular/forms";
import {ProfileCardService} from "../profile-card.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-basic-information',
  standalone: true,
  imports: [
    NgForOf,
    MatInput,
    NgIf,
    DatepickerComponent,
    CustomSelectionComponent,
    MatSelect,
    MatFormField,
    MatOption,
    MatCheckbox,
    ReactiveFormsModule
  ],
  templateUrl: './basic-information.component.html',
  styleUrl: './basic-information.component.scss'
})
export class BasicInformationComponent {
  isActive: boolean = true;
  formProfile = this.profileCardService.formProfile
  isEdit?: boolean
  contentItemLabel  = [
    {name : 'ID профиля', value: 'profileId'},
    {name : 'ID участника', value: 'participantId'},
    {name : 'Дата и время регистрации', value: 'registration'},
    {name : 'Возрастная категория', value: 'age'},
    {name : 'Обращение', value: 'salutation'},
    {name : 'Фамилия', value: 'lastName'},
    {name : 'Имя', value: 'firstName'},
    {name : 'Отчество', value: 'middleName'},
    {name : 'Пол', value: 'gender'},
    {name : 'Тип документа', value: 'typeDocument'},
    {name : 'Язык переписки', value: 'language'},

    {name : 'Серия', value: 'language'},
    {name : 'Номер', value: 'language'},
    {name : 'Кем выдан', value: 'language'},
    {name : 'Дата выдачи', value: 'language'},
    {name : 'Срок действия', value: 'language'},
    {name : 'Гражданство', value: 'language'},
    {name : 'Место рождения', value: 'language'},
    {name : 'Регион проживания', value: 'language'},
    {name : 'Адрес регистрации', value: 'language'},
    {name : 'Адрес фактического проживания', value: 'language'},
    {name : 'Скан документа', value: 'language'},

    {name : 'Family name', value: 'familyName'},
    {name : 'First name', value: 'firstNameEn'},
    {name : 'Middle name', value: 'middleNameEn'},
    {name : 'Фотография', value: 'photo'},
  ]
  age = [
    { name : '18-35', id : '1' },
  ]

  salutation= [
    { name : 'Господин', id : '1' },
  ]
  gender = [
    { name : 'Мужской', id : '1' },
  ]
  language = [
    { name : 'Русский', id : '1' },
  ]


constructor(public profileCardService:ProfileCardService) {
  this.profileCardService.isEdit$.pipe(untilDestroyed(this)).subscribe(isEdit =>{
    this.isEdit = isEdit
  });
}
}
