<div class="header">
  <div class="header-block-logo">
    <img [routerLink]="'/home'" src="assets/images/ul-kit-img/logo.svg">
    <div class="navbar-menu">
      <div
        [routerLink]="[item.url]"
        routerLinkActive="active"
        class="nav-item"
        *ngFor="let item of navMenu"
      >
        <span>{{item.name}}</span>
      </div>
    </div>
    <div class="info-user" (click)="isDropdownOpen = !isDropdownOpen">
      <span class="user-name">Иванов И.И</span>
      <img src="assets/images/ul-kit-img/arrow-down-white.svg" [class.rotate-180]="isDropdownOpen">
    </div>
  </div>

  <div class="dropdown" *ngIf="isDropdownOpen">
    <div class="dropdown-menu">
      <div class="menu-item">
        <div class="profile-info">
          <span class="text-inter-semibold info-text">Иванов Иван Иванович</span>
          <span class="text-inter-light role-text">Администратор</span>
        </div>
        <div class="other-info">
          <span class="text-inter-semibold setting-profile-text">Настройки профиля</span>
          <div (click)="logout()" class="exit-block">
            <img src="assets/images/ul-kit-img/exit.svg">
            <span class="text-inter-semibold">Выйти</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



