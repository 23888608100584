import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-login-completed',
  standalone: true,
  imports: [],
  templateUrl: './login-completed.component.html',
  styleUrl: './login-completed.component.scss'
})
export class LoginCompletedComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService) {
  }

  ngOnInit(): void {
    this.authService.isAuthenticated$
      .pipe(
        filter(flag => flag),
        untilDestroyed(this))
      .subscribe(isAuth => this.router.navigate(['/home']));
  }
}
