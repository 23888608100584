<div class="body">
  <div class="container">
    <div class="card-page">
      <app-header></app-header>
      <div class="card-page-main">
        <app-button-back/>
        <div class="card">
        <div class="title-card">
          <div class="title-card-left">
            <span class="name">Иванов Иван Иванович</span>
            <span class="text-inter-regular id-number">ID 12345789</span>
          </div>
          <div class="title-card-right">
            <div *ngIf="!isEdit" class="supplemental">
              <svg (click)="openDropDown('isProfileDropdownOpenSupplemental')"
                   [ngClass]="{'active-dropdown': dropdownService.dropdownStates.isProfileDropdownOpenSupplemental}" width="12" height="4" viewBox="0 0 12 4"
                   fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.33333 0.666748C0.6 0.666748 0 1.26675 0 2.00008C0 2.73341 0.6 3.33341 1.33333 3.33341C2.06667 3.33341 2.66667 2.73341 2.66667 2.00008C2.66667 1.26675 2.06667 0.666748 1.33333 0.666748ZM10.6667 0.666748C9.93333 0.666748 9.33333 1.26675 9.33333 2.00008C9.33333 2.73341 9.93333 3.33341 10.6667 3.33341C11.4 3.33341 12 2.73341 12 2.00008C12 1.26675 11.4 0.666748 10.6667 0.666748ZM6 0.666748C5.26667 0.666748 4.66667 1.26675 4.66667 2.00008C4.66667 2.73341 5.26667 3.33341 6 3.33341C6.73333 3.33341 7.33333 2.73341 7.33333 2.00008C7.33333 1.26675 6.73333 0.666748 6 0.666748Z"
                  fill="currentColor"/>
              </svg>
              <div class="dropdown dropdown-supplemental " *ngIf="dropdownService.dropdownStates.isProfileDropdownOpenSupplemental">
                <div class="dropdown-menu dropdown-menu-supplemental">
                  <app-dropdown [menuItems]="supplementalMenu"></app-dropdown>
                </div>
              </div>
            </div>
            <button (click)="canselForm()" class="button-red"><span class="text-inter-regular cancel">{{isEdit ?  'Отменить' : 'Удалить анкету'}}</span></button>
            <button (click)="isEditForm()"
                    [class.bth-orange]="!isEdit"
                    [class.button-green]="isEdit">
              <span class="text-inter-regular text-bth"
                    [class.text-bth]="!isEdit"
                    [class.accept]="isEdit">
                {{isEdit ?  'Сохранить' : 'Редактировать'}}</span>
            </button>
          </div>
        </div>
        <div class="form-card">
          <app-general-information></app-general-information>
          <app-basic-information></app-basic-information>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
