import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Injectable} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {ProfileCard} from "../../../core/interface/profile-card/profile-card.interface";

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})

export class ProfileCardService {

  isEdit = new BehaviorSubject<boolean>(false);
  isEdit$ = this.isEdit.asObservable();


  // для логики состояния активности формы,поменять на поля которые будут приходить с api
  formProfile: FormGroup<ProfileCard> = new FormGroup<ProfileCard>({
    test: new FormControl<boolean | null>(null, {nonNullable: true}),
    blocking: new FormControl<string | null>(null, {nonNullable: true}),
    category: new FormControl<string | null>(null, {nonNullable: true}),
    status: new FormControl<string | null>(null, {nonNullable: true}),
    email: new FormControl<string | null>(null, {nonNullable: true}),
    profileId:new FormControl<number | null>(null, {nonNullable: true}),
    participantId:new FormControl<number | null>(null, {nonNullable: true}),
    registration: new FormControl<Date | null>(null, {nonNullable: true}),
    age: new FormControl<number | null>(null, {nonNullable: true}),
    salutation: new FormControl<string | null>(null, {nonNullable: true}),
    lastName: new FormControl<string | null>(null, {nonNullable: true}),
    noLastName: new FormControl<boolean | null>(null, {nonNullable: true}),
    noMiddleName: new FormControl<boolean | null>(null, {nonNullable: true}),
    noFirstName: new FormControl<boolean | null>(null, {nonNullable: true}),
    firstName: new FormControl<string | null>(null, {nonNullable: true}),
    middleName: new FormControl<string | null>(null, {nonNullable: true}),
    gender: new FormControl<number | null>(null, {nonNullable: true}),
    language: new FormControl<number | null>(null, {nonNullable: true}),
    familyName: new FormControl<string | null>(null, {nonNullable: true}),
    firstNameEn: new FormControl<string | null>(null, {nonNullable: true}),
    middleNameEn: new FormControl<string | null>(null, {nonNullable: true}),
    photo: new FormControl<string | null>(null, {nonNullable: true}),
  });


  constructor() {
    this.getInfoFormEnable()
  }



  getInfoFormEnable(){
    this.isEdit$.pipe(untilDestroyed(this)).subscribe(isEdit => {
      if (isEdit) {
        this.formProfile.enable();
      } else {
        this.formProfile.disable();
      }
    });
  }

  isEditForm(): void {
    // Если isEdit был true, а теперь станет false, значит это момент сохранения формы (добавить метод отправки формы)
    this.isEdit.next(!this.isEdit.value);
  }
  canselForm(): void {
    this.isEdit.next(false);
  }
}
