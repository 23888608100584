import {ApplicationConfig, importProvidersFrom, LOCALE_ID} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {provideClientHydration} from "@angular/platform-browser";
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {BrowserAnimationsModule, NoopAnimationsModule, provideAnimations} from "@angular/platform-browser/animations";
import {authCodeFlowConfig} from "./sso.config";
import {AuthConfig, OAuthService, provideOAuthClient} from "angular-oauth2-oidc";
import {tokenInterceptor} from "./autorization/token.interceptor";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {AuthService} from "./core/services/auth/auth.service";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  provideNativeDateAdapter
} from "@angular/material/core";
import {CustomDateAdapter} from "./core/directive/custom-date.adapter";
import {DropdownService} from "./core/services/dropdown/dropdown.service";



export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const appConfig: ApplicationConfig = {
  providers: [
    NgxChartsModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    OAuthService,
    AuthService,
    DropdownService,
    provideRouter(routes),
    provideAnimations(),
    provideClientHydration(),
    provideOAuthClient(),
    provideNativeDateAdapter(),
    provideAnimationsAsync('noop'),
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    provideHttpClient(withInterceptors([tokenInterceptor])),
    importProvidersFrom([BrowserAnimationsModule]),

    { provide: DateAdapter, useClass: CustomDateAdapter},
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    { provide: AuthConfig, useValue: authCodeFlowConfig },

  ]
};
