import { Component } from '@angular/core';
import {HeaderComponent} from "../../layout/header/header.component";

@Component({
  selector: 'app-mailing',
  standalone: true,
    imports: [
        HeaderComponent
    ],
  templateUrl: './mailing.component.html',
  styleUrl: './mailing.component.scss'
})
export class MailingComponent {

}
