import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {provideClientHydration} from "@angular/platform-browser";
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {BrowserAnimationsModule, NoopAnimationsModule, provideAnimations} from "@angular/platform-browser/animations";
import {authCodeFlowConfig} from "./sso.config";
import {environment} from "../environments/environment";
import {AuthConfig, OAuthService, provideOAuthClient} from "angular-oauth2-oidc";
import {tokenInterceptor} from "./autorization/token.interceptor";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {AuthService} from "./core/services/auth/auth.service";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    NgxChartsModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    OAuthService,
    AuthService,
    provideAnimations(),
    provideClientHydration(),
    provideOAuthClient(),
    provideAnimationsAsync('noop'),
    provideHttpClient(withInterceptors([tokenInterceptor])),
    { provide: AuthConfig, useValue: authCodeFlowConfig },
    importProvidersFrom([BrowserAnimationsModule]),
  ]
};
