import {Component, Input, OnInit} from '@angular/core';
import {
  MatCalendar, MatCalendarCellClassFunction,
  MatDatepicker,
  MatDatepickerContent,
  MatDatepickerInput,
  MatDatepickerToggle
} from "@angular/material/datepicker";
import {MatFormField, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {NgClass} from "@angular/common";
import {
  ValidatorDateDirective
} from "../../../../../app/core/directive/validators-datepicker.directive";

@Component({
  selector: 'custom-datepicker',
  standalone: true,
  imports: [
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatFormField,
    MatInput,
    MatSuffix,
    ReactiveFormsModule,
    MatDatepickerContent,
    MatCalendar,
    NgClass,
    ValidatorDateDirective
  ],
  templateUrl: './datepicker.component.html',
  styleUrl: './datepicker.component.scss'
})
export class DatepickerComponent{
  @Input() control: FormControl<any | null> = new FormControl(null);



  isWeekendDay: MatCalendarCellClassFunction<Date> = (date, view) => {
    return (date.getDay() === 0 || date.getDay() === 6) ? 'weekend' : '';
  };

}
