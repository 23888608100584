import { Component } from '@angular/core';
import {HeaderComponent} from "../../layout/header/header.component";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {ProfileTableComponent} from "./profile-table/profile-table/profile-table.component";
import {ProfileFilterComponentEvent} from "./profile-filter/profile-filter-event/profile-filter.component-event";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {CustomSelectionComponent} from "../../../assets/shared/components/select/custom-selection/custom-selection.component";
import {MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {PaginatorComponent} from "../../../assets/shared/components/paginator/paginator.component";

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    HeaderComponent,
    NgIf,
    NgClass,
    NgForOf,
    ProfileTableComponent,
    ProfileFilterComponentEvent,
    DropdownModule,
    FormsModule,
    CustomSelectionComponent,
    MatLabel,
    MatInput,
    PaginatorComponent
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent {
  item = [
    {id: '1', name:'ID объекта'},
    {id: '2', name:'Наименование'},
    {id: '3', name:'Описание'},
    {id: '4', name:'Наименование на английском языке'},
    {id: '5', name:'Тематический блок'},
    {id: '6', name:'Место проведения'},
    {id: '7', name:'Тест1'},
    {id: '8', name:'Тест2'},
  ]
  isDropdownOpenSupplemental: boolean = false
  isDropdownOpenFilterEvent: boolean = false
  isDropdownOpenConstructor: boolean = false
  isDropdownOpenSetting: boolean = false
  isDropdownOpenSubSetting: boolean = false
  supplementalMenu = [
    { label: 'Создать карточку', url: '' },
    { label: 'Блокировать', url: '' },
    { label: 'Удалить', url: '' },
    { label: 'Зарегистрировать на событие', url: '' },
    { label: 'Выбрать слот питания', url: '' }
  ];
  settingsMenu = [
    {
      label: 'Списки ВФМС',
      url: '',
      subMenu: [
        { label: 'Волонтеры', url: '' },
        { label: 'Участники', url: '' },
        { label: 'СМИ', url: '' },
        { label: 'Гости', url: '' },
        { label: 'Тех. персонал', url: '' }
      ],
      icon: 'assets/images/ul-kit-img/arrow-black.svg',
    },
    { label: 'Отчеты',
      subMenu: null,
      icon: null,
    },
    { label: 'История изменения атрибутов',
      subMenu: null,
      icon: null,
    },
  ];
  constructorMenu = [
    { label: 'Группировка атрибутов', url: '' },
    { label: 'Расположение атрибутов', url: '' },
    { label: 'История изменения атрибутов', url: '' },
    { label: 'Настройка атрибутов', url: '' },
    { label: 'Массовое изменение атрибутов', url: '' },
    { label: 'Шаблоны', url: '' }
  ];
}
