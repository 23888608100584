import {HttpErrorResponse, HttpInterceptorFn} from '@angular/common/http';
import {inject} from "@angular/core";
import {AuthService} from '../core/services/auth/auth.service';
import {tap} from "rxjs/operators";

import {Router} from "@angular/router";



export const tokenInterceptor: HttpInterceptorFn = (req, next) => {

  const authStorage = inject(AuthService);
  const router = inject(Router);






  if (authStorage.hasValidToken()) {
    let token = authStorage.getAccessToken();
    let header = 'Bearer ' + token;

    let headers = req.headers
      .set('Authorization', header);

    req = req.clone({headers});
  }

  return next(req).pipe(tap(
    () => {
    },
    (error: HttpErrorResponse) => {

      switch (error.status) {
        case 403:

          break;
        case 404:

          break;
        // case 500:
        //   break;
        // case 504:
        //   break;
        default:

          break;
      }
    },
    () => {

    }
  ));
};
