import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[app-validator-date]',
  standalone: true
})
export class ValidatorDateDirective {
  private regex: RegExp | null = null;

  @Input('app-validator-date') set regexValue(value: string) {
    this.regex = new RegExp(value);
  }

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent): void {
    if (this.regex && !this.regex.test(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event']) onInput(event: any): void {
    this.checkInput(event);
    this.dateChanged(event);
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Backspace') {
      const input = event.target as HTMLInputElement;
      const caretStart = input.selectionStart ?? 0;
      const caretEnd = input.selectionEnd ?? 0;
      if (input.value[caretStart - 1] === '.' && !isNaN(parseInt(input.value[caretStart - 2]))) {
        const valueBeforeCaret = input.value.slice(0, caretStart - 1);
        const valueAfterCaret = input.value.slice(caretEnd);
        input.value = valueBeforeCaret + valueAfterCaret;
        event.preventDefault();
        input.setSelectionRange(caretStart - 1, caretStart - 1);
      }
    }
  }

  checkInput(e: any): void {
    const value = e.target.value;
    if (value.match(/^\d{1}$/) !== null && value[0] > 3) {
      e.target.value = '0' + value + '.';
    } else if (value.match(/^\d{2}$/) !== null) {
      e.target.value = value + '.';
    } else if (value.match(/^\d{2}.\d{1}$/) !== null && value[3] > 1) {
      e.target.value = '' + value[0] + value[1] + '.0' + value[3] + '.';
    } else if (value.match(/^\d{2}.\d{2}$/) !== null) {
      e.target.value = value + '.';
    }
    const parts = value.split('.');
    const day = parseInt(parts[0]);
    const month = parseInt(parts[1]);

    if (parts.length === 3 && month > 12) {
      e.target.value = '31' + '.' + '12' + '.' + parts[2];
    }
  }

  dateChanged(event: any): void {
    let value = event.target.value;
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    event.target.value = value;
  }
}
