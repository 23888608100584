import {ChangeDetectionStrategy, Component, ElementRef,ViewChild, ViewEncapsulation} from '@angular/core';
import {NgForOf} from "@angular/common";
import {RouterLink} from "@angular/router";
import {BarChartModule, ChartCommonModule, Color, PieChartModule, ScaleType} from "@swimlane/ngx-charts";
import {ChartsService} from "../../core/services/charts/charts.service";
import {AuthService} from "../../core/services/auth/auth.service";


@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    NgForOf,
    RouterLink,
    BarChartModule,
    ChartCommonModule,
    PieChartModule,
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent {
  @ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef;
  activeCountry: string | null = null;
  activeApplication: string | null = null;
  activeCountryColors: Color = {
    name: 'custom',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#00AA40', '#FF6B00', '#AC182D', '#0082C2', '#E81F76', '#6338A9']
  };
  activeApplicationColors: Color = {
    name: 'custom',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#AC182D', '#6338A9',]
  };
  countriesCharts = [
    {name: "AUS", value: 10, longName: "Австралия", membersValue: 4210},
    {name: "AZE", value: 70, longName: "Азербайжан", membersValue: 75300},
    {name: "BWA", value: 92, longName: "Ботсвана", membersValue: 94692},
    {name: "BDI", value: 2, longName: "Бурунди", membersValue: 2000},
    {name: "CHN", value: 20, longName: "Китай", membersValue: 23520},
    {name: "COG", value: 40, longName: "Остров Норфолк", membersValue: 44440},
    {name: "NFK", value: 10, longName: "Острова Теркс", membersValue: 510},
    {name: "TCA", value: 70, longName: "Южный Судан", membersValue: 72600},
    {name: "SSD", value: 92, longName: "Южная Африка", membersValue: 94692},
    {name: "ZAF", value: 20, longName: "Конго", membersValue: 2450}
  ];
  applications = [
    {name: "Общее количество\nподанных заявок", value: 50},
    {name: "Общее кол-во одобренных заявок", value: 50},
  ];
  homeNavBar = [
    {
      icon: 'assets/images/ul-kit-img/profile.svg',
      label: 'Профили',
      description: 'Реестр участников, работа\nс данными.',
      url: '/profile',
    },
    {
      icon: 'assets/images/ul-kit-img/mailing.svg',
      label: 'Рассылка',
      description: 'Шаблоны писем, отчеты\nпо рассылкам.',
      url: '/mailing',
    },
    {
      icon: 'assets/images/ul-kit-img/applications.svg',
      label: 'Заявки участников',
      description: 'Модерация, отбор участников.',
      url: '/application',
    },
    {
      icon: 'assets/images/ul-kit-img/instructions.svg',
      label: 'Инструкции',
      description: 'Инструкции, документы\nпо работе в системе.',
      url: '/instructions',
    },
    {
      icon: 'assets/images/ul-kit-img/analytics.svg',
      label: 'Аналитика',
      description: 'Дашборды, графики,\nотчеты ключевых показателей.',
      url: '/analytics',
    },
    {
      icon: 'assets/images/ul-kit-img/services.svg',
      label: 'Сервисы',
      description: 'Проживание и питание.',
      url: '',
    },
    {
      icon: 'assets/images/ul-kit-img/seating.svg',
      label: 'Рассадка',
      description: 'Распределение участников\nпо местам.',
      url: '',
    },

  ]
  isDropdownOpen = false;

  constructor(private chartsService: ChartsService,private authService: AuthService) {

  }

  formatPercentage = (value: number): string => {
    return this.chartsService.formatPercentage(value);
  }

  formatYAxisTick = (value: number): string => {
    return this.chartsService.formatYAxisTick(value);
  }

  onItemSelect(itemName: string, type: 'activeCountry' | 'activeApplication'): void {
    if (type === 'activeCountry') {
      console.log(type)
      this.activeCountry = this.chartsService.onItemSelect(this.activeCountry, itemName);
      this.updateTickClasses()
    } else if (type === 'activeApplication') {
      this.activeApplication = this.chartsService.onItemSelect(this.activeApplication, itemName);
    }
  }

  updateTickClasses() {
    this.chartsService.updateTickClasses(this.activeCountry)
  }

  getCustomColors(activeItem: string | null, data: any[], baseColors: Color): any {
    return this.chartsService.getCustomColors(activeItem, data, baseColors);
  }

  async logout(){
    await this.authService.logout()
  }
}
