import {ChangeDetectionStrategy, Component, ElementRef,ViewChild, ViewEncapsulation} from '@angular/core';
import {NgForOf} from "@angular/common";
import {RouterLink} from "@angular/router";
import {BarChartModule, ChartCommonModule, Color, PieChartModule, ScaleType} from "@swimlane/ngx-charts";
import {ChartsService} from "../../core/services/charts/charts.service";
import {AuthService} from "../../core/services/auth/auth.service";
import {
  DropdownProfileComponent
} from "../../../assets/shared/components/dropdown/dropdown-profile/dropdown-profile.component";


@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    NgForOf,
    RouterLink,
    BarChartModule,
    ChartCommonModule,
    PieChartModule,
    DropdownProfileComponent,
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent {
  @ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef;
  activeCountry: string | null = null;
  activeApplication: string | null = null;
  activeCountryColors: Color = {
    name: 'custom',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#00AA40', '#FF6B00', '#AC182D', '#0082C2', '#E81F76', '#6338A9']
  };
  activeApplicationColors: Color = {
    name: 'custom',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#AC182D', '#6338A9',]
  };
  countriesCharts = [
    {name: "AUS", value: 10, longName: "Австралия", membersValue: 4210},
    {name: "AZE", value: 70, longName: "Азербайжан", membersValue: 75300},
    {name: "BWA", value: 92, longName: "Ботсвана", membersValue: 94692},
    {name: "BDI", value: 2, longName: "Бурунди", membersValue: 2000},
    {name: "CHN", value: 20, longName: "Китай", membersValue: 23520},
    {name: "COG", value: 40, longName: "Остров Норфолк", membersValue: 44440},
    {name: "NFK", value: 10, longName: "Острова Теркс", membersValue: 510},
    {name: "TCA", value: 70, longName: "Южный Судан", membersValue: 72600},
    {name: "SSD", value: 92, longName: "Южная Африка", membersValue: 94692},
    {name: "ZAF", value: 20, longName: "Конго", membersValue: 2450}
  ];
  applications = [
    {name: "Общее количество\nподанных заявок", value: 50},
    {name: "Общее кол-во одобренных заявок", value: 50},
  ];
  homeNavBarNotLong = [
    // временно
    {
      icon: 'assets/images/ul-kit-img/seating.svg',
      label: 'Портал',
      description: '',
      url: 'https://wyffest.com/',
    },
    {
      icon: 'assets/images/ul-kit-img/mailing.svg',
      label: 'Рассылка(временно)',
      description: 'Шаблоны писем, отчеты\nпо рассылкам.',
      url: 'https://notifications.wyffest.com/mailings',
    },
    {
      icon: 'assets/images/ul-kit-img/applications.svg',
      label: 'Участники(временно)',
      description: 'Модерация, отбор участников.',
      url: 'https://members.wyffest.com/',
    },
    {
      icon: 'assets/images/ul-kit-img/analytics.svg',
      label: 'НСИ',
      description: 'Хранение и учет справочников.',
      url: 'https://nsi.wyffest.com/cm',
    },
    {
      icon: 'assets/images/ul-kit-img/analytics.svg',
      label: 'Мероприятия',
      description: 'Создание и модерация информации о мероприятиях.',
      url: 'https://event.festyouth.com/cm/event',
    },
    {
      icon: 'assets/images/ul-kit-img/seating.svg',
      label: 'Фотобанк',
      description: 'Работа с фоторгафиями в разделе\nФотобанк на портале',
      url: 'https://photo.wyffest.com/cm',
    },
  ]
  homeNavBar = [
    {
      icon: 'assets/images/ul-kit-img/profile.svg',
      label: 'Профили',
      description: 'Реестр участников, работа\nс данными.',
      url: '/profile',
    },
    {
      icon: 'assets/images/ul-kit-img/mailing.svg',
      label: 'Рассылка',
      description: 'Шаблоны писем, отчеты\nпо рассылкам.',
      url: '/mailing',
    },
    {
      icon: 'assets/images/ul-kit-img/applications.svg',
      label: 'Заявки участников',
      description: 'Модерация, отбор участников.',
      url: '/application',
    },
    {
      icon: 'assets/images/ul-kit-img/instructions.svg',
      label: 'Инструкции',
      description: 'Инструкции, документы\nпо работе в системе.',
      url: '/instructions',
    },
    {
      icon: 'assets/images/ul-kit-img/analytics.svg',
      label: 'Аналитика',
      description: 'Дашборды, графики,\nотчеты ключевых показателей.',
      url: '/analytics',
    },
    {
      icon: 'assets/images/ul-kit-img/services.svg',
      label: 'Сервисы',
      description: 'Проживание и питание.',
      url: '/home',
    },
    {
      icon: 'assets/images/ul-kit-img/seating.svg',
      label: 'Рассадка',
      description: 'Распределение участников\nпо местам.',
      url: '/home',
    },
    {
      icon: 'assets/images/ul-kit-img/delete-application.svg',
      label: 'Заявки на удаление',
      description: 'Удаление персональных данных\n(ЛК).',
      url: '/home',
    },
  ]
  isDropdownOpen = false;

  constructor(private chartsService: ChartsService,private authService: AuthService) {

  }

  formatPercentage = (value: number): string => {
    return this.chartsService.formatPercentage(value);
  }

  formatYAxisTick = (value: number): string => {
    return this.chartsService.formatYAxisTick(value);
  }

  onItemSelect(itemName: string, type: 'activeCountry' | 'activeApplication'): void {
    if (type === 'activeCountry') {
      this.activeCountry = this.chartsService.onItemSelect(this.activeCountry, itemName);
      this.updateTickClasses()
    } else if (type === 'activeApplication') {
      this.activeApplication = this.chartsService.onItemSelect(this.activeApplication, itemName);
    }
  }

  updateTickClasses() {
    this.chartsService.updateTickClasses(this.activeCountry)
  }

  getCustomColors(activeItem: string | null, data: any[], baseColors: Color): any {
    return this.chartsService.getCustomColors(activeItem, data, baseColors);
  }


}
