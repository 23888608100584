import { Injectable } from '@angular/core';
import { CanActivate, CanMatch, Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route, UrlSegment } from '@angular/router';
import { Observable, of } from 'rxjs';

import { AuthService } from '../core/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuard implements CanActivate, CanMatch {
  constructor(private authorize: AuthService, private router: Router) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let isValidToken = this.authorize.hasValidToken();

    this.handleAuthorization(isValidToken, state.url)

    return of(isValidToken);
  }

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {

    let isValidToken = this.authorize.hasValidToken();

    this.handleAuthorization(isValidToken, '/' + segments.map(s => s.path).join('/'));

    return of(isValidToken);
  }

  private handleAuthorization(isAuthenticated: boolean, url: string) {
    if (!isAuthenticated) {
      this.router.navigate(['/'], {
        queryParams: { returnUrl: url }
      });
    }
  }
}
