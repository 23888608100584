import { Component } from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {
  DropdownProfileComponent
} from "../../../assets/shared/components/dropdown/dropdown-profile/dropdown-profile.component";
import {DropdownService} from "../../core/services/dropdown/dropdown.service";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgForOf,
    RouterLink,
    RouterLinkActive,
    NgIf,
    DropdownProfileComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  isDropdownOpen = false;
  navMenu = [
    {
      name: 'Профили',
      url: '/profile',
    },
    {
      name: 'Рассылка',
      url: '/mailing',
    },
    {
      name: 'Заявки на мероприятия',
      url: '/application',
    },
    {
      name: 'Инструкции',
      url: '/instructions',
    },
    {
      name: 'Аналитика',
      url: '/analytics',
    },
  ];

constructor(public dropdownService: DropdownService) {
}


  openDropDown(dropdownName: string): void {
    this.dropdownService.openDropDown(dropdownName);
  }
}
