import { Component } from '@angular/core';
import {HeaderComponent} from "../../layout/header/header.component";

@Component({
  selector: 'app-application',
  standalone: true,
    imports: [
        HeaderComponent
    ],
  templateUrl: './application.component.html',
  styleUrl: './application.component.scss'
})
export class ApplicationComponent {

}
