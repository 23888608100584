import { AuthConfig } from "angular-oauth2-oidc";
import {environment} from "../environments/environment";


const { auth } = environment;

export const authCodeFlowConfig: AuthConfig = {
  issuer: auth.issuer,

  redirectUri: `${auth.selfUrl}/signin-oidc`,
  postLogoutRedirectUri: `${auth.selfUrl}/signout-callback-oidc`,
  clientId: auth.clientId,
  responseType: 'code',
  scope: auth.scope,
  showDebugInformation: !environment.production,

  tokenEndpoint: `${auth.issuer}/connect/token`,
  userinfoEndpoint: `${auth.issuer}/connect/userinfo`,
  logoutUrl: `${auth.issuer}/connect/endsession`,
  revocationEndpoint: `${auth.issuer}/connect/revocation`,
};
