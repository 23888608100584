import { Component, EventEmitter, Output } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-button-back',
  templateUrl: './button-back.component.html',
  styleUrls: ['./button-back.component.css'],
  standalone: true,
})
export class ButtonBackComponent {
  @Output() click: EventEmitter<void> = new EventEmitter();

  constructor(private location: Location) {
  }

  onClick(): void {
    if (this.click.observers.length > 0)
      this.click.emit();
    else
      this.location.back();
  }
}
