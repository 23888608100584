<div class="body">
<div class="container">
  <div class="home-page">
    <div class="header">
      <div class="header-page">
        <div class="header-page-home">
          <div class="header-block-logo">
            <img src="assets/images/ul-kit-img/logo.svg">
              <div class="dropdown">
                <div class="info-user" (click)="isDropdownOpen = !isDropdownOpen">
                  <span class="user-name">Иванов И.И</span>
                  <img src="assets/images/ul-kit-img/arrow-down-white.svg" [class.rotate-180]="isDropdownOpen">
                </div>
                <app-dropdown-profile *ngIf="isDropdownOpen"></app-dropdown-profile>
            </div>
          </div>
          <div class="welcome">
            <div class="welcome-title">
              <h1>Добро пожаловать в систему, Иван!</h1>
              <span>Администратор</span>
            </div>
            <div class="welcome-setting">
              <img src="assets/images/ul-kit-img/setting-profile.svg">
              <span>Настройки профиля</span>
            </div>
          </div>
        </div>
        <div class="nav-block">
          <div [routerLink]="[item.url]" *ngFor="let item of homeNavBar" class="nav-item">
            <div class="nav-block-label">
              <img [src]="item.icon" alt="{{ item.label }}" class="nav-icon">
              <span class="text-inter-regular nav-label">{{ item.label }}</span>
            </div>
            <div class="nav-content">
              <p class="text-inter-regular nav-description">{{ item.description }}</p>
            </div>
          </div>
        </div>
        <div class="nav-block">
          <a [href]="item.url" *ngFor="let item of homeNavBarNotLong" class="nav-item" target="_blank" rel="noopener noreferrer">
            <div class="nav-block-label">
              <img [src]="item.icon" alt="{{ item.label }}" class="nav-icon">
              <span class="text-inter-regular nav-label">{{ item.label }}</span>
            </div>
            <div class="nav-content">
              <p class="text-inter-regular nav-description">{{ item.description }}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="home-block">
      <div *ngIf="!activeCountry" class="download-statistics">
        <span>Статистика</span>
        <img src="assets/images/ul-kit-img/download.svg">
      </div>
      <div class="statistics-container">
        <div class="statistics statics-left">
          <div class="statistics-main">
            <div class="statistics-block">
              <div class="label-charts main-charts">
                <span>Участники в разрезе стран (топ-10)</span>
              </div>
              <div  class="charts-countries">
                <ngx-charts-bar-vertical
                  [view]="[540, 310]"
                  [showXAxisLabel]="true"
                  [xAxis]="true"
                  [yAxis]="true"
                  [tooltipDisabled]="true"
                  [dataLabelFormatting]="formatPercentage"
                  [yAxisTickFormatting]="formatYAxisTick"
                  [showDataLabel]="true"
                  [yScaleMax]="100"
                  [yAxisTicks]="[0,10,20,30,40,50,60,70,80,90,100]"
                  [results]="countriesCharts"
                  [scheme]="getCustomColors(activeCountry, countriesCharts, activeCountryColors)"
                  (select)="onItemSelect($event.name, 'activeCountry')" style="padding-left: 12px">
                </ngx-charts-bar-vertical>
              </div>
            </div>
            <div class="info-charts-bar">
              <ul class="info-country">
                <li *ngFor="let country of countriesCharts; let i = index"
                    (click)="onItemSelect(country.name,'activeCountry')"
                    [ngClass]="{'inactive': activeCountry && activeCountry !== country.name}">
                  <span class="point"
                        [ngStyle]="{'color': activeCountryColors.domain[i % activeCountryColors.domain.length]}">•</span>
                  <span class="text-inter-semibold name-country">{{ country.longName }}</span>
                  <br>
                  <div class="members-info">
                    <span class="text-inter-regular">{{country.membersValue}}</span>
                    <span class="text-inter-regular">участников ({{ country.value }}%)</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="statistics statistics-application">
          <div class="info-members">
            <div class="label-charts label-count">
              <span class="text-inter-semibold">Общее кол-во участников в системе</span>
            </div>
            <div class="members-count">
              <span class="count-member">
                132 000
              </span>
              <div class="count-block">
                <span class="count">(+12 000)</span><span>за день</span>
              </div>
            </div>
            <div class="members-count count-day">
              <div class="label-charts label-count">
                <span class="text-inter-semibold">Российских участников</span>
              </div>
              <div class="members-count">
                <span>100 000</span>
                <div class="count-block">
                  <span class="count">(+2 000)</span><span>за день</span>
                </div>
              </div>
            </div>
            <div class="members-count count-day">
              <div class="label-charts label-count">
                <span class="text-inter-semibold">Иностранных участников</span>
              </div>
              <div class="members-count">
                <span>32 000</span>
                <div class="count-block">
                  <span class="count">(+900)</span><span>за день</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="statistics-column-container">
          <div class="statistics statistics-event">
            <div class="info-members info-event">
              <div class="label-charts label-count">
                <span class="text-inter-semibold">Общее кол-во мероприятий</span>
              </div>
              <div class="event-count">
                <span>50</span>
              </div>
            </div>
          </div>
          <div class="statistics statistics-event">
            <div class="info-members info-event">
              <div class="label-charts label-count">
                <span class="text-inter-semibold">Общее кол-во участников мероприятий</span>
              </div>
              <div class="event-count-members">
                <span>70 000</span>
              </div>
            </div>
          </div>
        </div>
        <div class="statistics statistics-application">
          <div class="block-application">
            <ul class="info-application">
              <li *ngFor="let list of applications; let i = index"
                  (click)="onItemSelect(list.name,'activeApplication')"
                  [ngClass]="{'inactive': activeApplication && activeApplication !== list.name}">
                  <span class="point"
                        [ngStyle]="{'color': activeApplicationColors.domain[i % activeApplicationColors.domain.length]}">•</span>
                <span class="text-inter-semibold name-country">{{ list.name }}</span>
                <br>
                <div class="members-info">
                  <span class="text-inter-regular">{{list.value}} заявок</span>
                </div>
              </li>
            </ul>
            <ngx-charts-pie-chart
              [view]="[200, 200]"
              [tooltipDisabled]="true"
              [arcWidth]="0.50"
              [doughnut]="true"
              [results]="applications"
              [scheme]="getCustomColors(activeApplication, applications, activeApplicationColors)"
              (select)="onItemSelect($event.name, 'activeApplication')">
            </ngx-charts-pie-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>




