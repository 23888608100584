import { Component } from '@angular/core';
import {ICustomTableHeaders, TableComponent} from "../../../../../assets/shared/components/table/table.component";
import {MatCheckbox} from "@angular/material/checkbox";
import {NgClass, NgForOf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {Router} from "@angular/router";
import {NgxPaginationModule} from "ngx-pagination";

@Component({
  selector: 'app-profile-table',
  standalone: true,
  imports: [
    TableComponent,
    MatCheckbox,
    NgForOf,
    FormsModule,
    NgClass,
    NgxPaginationModule
  ],
  templateUrl: './profile-table.component.html',
  styleUrl: './profile-table.component.scss'
})
export class ProfileTableComponent {
  tableHeaders: ICustomTableHeaders[] = [];
  items = Array.from({ length: 23 }, (_, i) => ({
    checked: null,
    status: 'Заблокирован',
    description: 'Пишет гневные письма',
    participant: 'Российский участник',
    id1: '1234515123',
    id2: '1234515123',
    date: '10.02.2024 в 20:53',
    age: '14-18',
    title: 'Господин'
  }));
  constructor(private router: Router) {
    this.updateTableHeaders()
  }

  routerInCard(){
    this.router.navigate(['profile/profile-card'])
  }
  updateTableHeaders() {
    this.tableHeaders = [
      {columnDisplayName: '(600 000)', columnName: 'id'},
      {columnDisplayName: 'Тест', columnName: 'test'},
      {columnDisplayName: 'Статус ЛК', columnName: 'test'},
      {columnDisplayName: 'Причина блокировки', columnName: 'test1'},
      {columnDisplayName: 'Категория', columnName: 'test2'},
      {columnDisplayName: 'ID профиля', columnName: 'test3'},
      {columnDisplayName: 'ID участника', columnName: 'test4'},
      {columnDisplayName: 'Дата и время регистрации', columnName: 'test5'},
      {columnDisplayName: 'Возрастная категория', columnName: 'test6'},
      {columnDisplayName: 'Обращение', columnName: 'test7'},
    ];
  }

}
