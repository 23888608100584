import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  override getDayOfWeekNames(): string[] {
    return ['Вc', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
  }
  override getFirstDayOfWeek(): number {
    return 1;
  }
  override format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'MMMM YYYY') {
      let formattedDate = new Intl.DateTimeFormat('ru-RU', { month: 'long', year: 'numeric' }).format(date);
      formattedDate = formattedDate.replace(/г\./, '');
      formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
      return formattedDate;
    }
    return super.format(date, displayFormat);
  }

}
