<div class="add-event">
  <span>Добавить мероприятие</span>
  <div class="custom-multiselect-search-event">
    <mat-label class="text-inter-regular">Выберите мероприятия</mat-label>
    <custom-selection

      [multipleCheckBox]="true"
      [control]="eventControl"
      [options]="item"></custom-selection>

    <div  class="selected-event">
      <span class="name-event" *ngFor="let selected of getSelectedItems()">{{ selected?.name }}</span>
    </div>
  </div>
  <div  class="bth-search">
    <button (click)="closeDropdown()"  class="button-red"><span class="cancel">Отменить</span></button>
    <button (click)="closeDropdown()" class="button-green"><span class="accept">Сохранить</span></button>
  </div>
</div>
