export class DropdownService {
   dropdownStates = {
    isDropdownOpenFilterEvent: false,
    isDropdownOpenSetting: false,
    isDropdownOpenSupplemental: false,
    isDropdownOpenConstructor: false,
    isDropdownOpenProfileHeader: false,
    isProfileDropdownOpenSupplemental: false,
  };

  openDropDown(dropdownName: string): void {
    Object.keys(this.dropdownStates).forEach(key => {
      this.dropdownStates[key as keyof typeof this.dropdownStates] = (key === dropdownName)
        ? !this.dropdownStates[key as keyof typeof this.dropdownStates]
        : false;
    });
  }

}
