<div class="menu-item">
  <div *ngFor="let menuItem of menuItems"
       (click)="menuItem.label === 'Списки ВФМС' ? isDropdownOpenSubSetting = !isDropdownOpenSubSetting : false"
       class="other-info">
    <div class="block-arrow">
      <span  [ngClass]="{'active-dropdown': isDropdownOpenSubSetting && menuItem.label === 'Списки ВФМС'}" class="setting-profile-text">{{menuItem.label}}</span>
      <img *ngIf="menuItem?.icon && menuItem?.subMenu"
           [src]="menuItem?.icon"
           [ngClass]="{'rotate-180': isDropdownOpenSubSetting}" class="arrow-icon"/>
    </div>

    <div *ngIf="menuItem?.subMenu && isDropdownOpenSubSetting" class="other-info sub-menu">
      <span *ngFor="let subMenuItem of menuItem?.subMenu"
            class="setting-profile-text">
        {{ subMenuItem.label }}
      </span>
    </div>
  </div>
</div>

