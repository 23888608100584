import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  constructor(private oauthService: OAuthService) {}

  registerEvents() {
    this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.events
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.type === 'token_received') {
          this.isAuthenticatedSubject$.next(true);
        }
        if (e.type === 'logout') {
          this.isAuthenticatedSubject$.next(false);
        }
      });

    this.oauthService.tryLogin().then(() => {
      this.isAuthenticatedSubject$.next(true);
    });
  }

  async login() {
    await this.oauthService.loadDiscoveryDocumentAndLogin();
  }

  async logout() {
    await this.oauthService.revokeTokenAndLogout();
  }

  hasValidToken(): boolean {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
  }

  getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }
}
